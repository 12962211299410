import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

const root = ReactDOM.createRoot(document.getElementById('root'));

const keycloak = {
  client_id: window.env.CLIENT_ID,
  authority: window.env.AUTH_URL,
  redirect_uri: `${window.location.origin}/login`,
  scope: 'openid email profile',
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

root.render(
  <AuthProvider {...keycloak}>
    <App />
  </AuthProvider>
);

reportWebVitals();