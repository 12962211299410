import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { deDE } from '@mui/material/locale';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Claims from './components/Claims';
import Complete from './components/Complete/Complete';
import Login from './components/Login';
import Nav from './components/Nav';
import Report from './components/Report';
import PrivateRoute from './utlis/PrivateRoute';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#335F9A"
      },
      profile: {
        main: "#2C2C2C",
        contrastText: "#F5F5F5"
      },
      signout: {
        main: "#E3E3E3",
        border: "#767676",
        contrastText: "#1E1E1E"
      }
    },
    typography: {
      fontFamily: 'Ubuntu'
    }
  },
    deDE
  );

  const initalReportConfig = {
    "email": "",
    "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
    "content": "",
    "attachments": [],
    "contracts": [],
    "loading": false
  };

  const { events, signinSilent, signoutSilent } = useAuth()

  const reportRef = useRef(initalReportConfig);

  useEffect(() => {
    return () => {
      events.addAccessTokenExpiring(() => {
        signinSilent()
          .then(() => reportRef.current = { ...reportRef.current })
          .catch(err => {
            if (err.response?.status === 400) {
              signoutSilent();
            }
          });
      })
    };
  }, [events, signinSilent, signoutSilent]);

  const Redirect = () => {
    return <Navigate replace to="/login" />;
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Nav />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/report" element={<PrivateRoute redirect={"/report"} ><Report reportRef={reportRef} /></PrivateRoute>} />
          <Route exact path="/complete" element={<PrivateRoute redirect={"/complete"} ><Complete reportRef={reportRef} /></PrivateRoute>} />
          <Route exact path="/claims" element={<PrivateRoute redirect={"/claims"}><Claims reportRef={reportRef} /></PrivateRoute>} />
          <Route exact path="*" element={<Redirect />} />
        </Routes>
      </ThemeProvider >
    </BrowserRouter>
  );
}

export default App;