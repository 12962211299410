import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from 'moment/moment';
import { Fragment, useEffect, useRef, useState } from "react";

const Overview = ({ claimProps }) => {
    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [isOverflowing1, setIsOverflowing1] = useState(false);
    const [isOverflowing2, setIsOverflowing2] = useState(false);
    const [isOverflowing3, setIsOverflowing3] = useState(false);
    const [carCondition, setCarCondition] = useState(false);
    const [phvCondition, setPhvCondition] = useState(false);
    const [claim, setClaim] = useState();
    const [noClaim, setNoClaim] = useState(true);
    const [personalInjury, setPersonalInjury] = useState(false);
    const [risk, setRisk] = useState();

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);

    useEffect(() => {
        // const newclaim = {
        //     ...claimProps,
        //     personalInjury: true,
        //     messageType: "Rechnungsnachreichung",
        //     chatClaimRouting: {
        //         ...claimProps?.chatClaimRouting,
        //         car: false,
        //         negligence: true
        //     },
        //     "coverageDetermination": {
        //         "coverages": [{
        //             "title": "Besondere Bedingungen für Leitungswasseraustritt",
        //             "description": "Versicherungsschutz besteht für Schäden durch austretendes Leitungswasser, wenn diese durch eine der versicherten Ursachen entstanden sind.",
        //             "deductble": "500",
        //             "maxInsuredSum": "Kein spezifischer Betrag angegeben"
        //         }, {
        //             "title": "Allgemeine Bedingungen für die Kaskoversicherung",
        //             "description": "Kaskoplus-Paket - Kollision des in Bewegung befindlichen Fahrzeuges mit Tieren jeglicher Art (ausgenommen Kollisionen, die in der Grunddeckung versichert sind)",
        //             "deductble": "500",
        //             "maxInsuredSum": "Keine spezifische Begrenzung, da Teil der Vollkasko-Grunddeckung"
        //         }],
        //         "conditions": [{
        //             "title": "Artikel 9 - Obliegenheiten",
        //             "description": "Der Versicherungsnehmer ist verpflichtet, den Versicherungsfall unverzüglich, längstens innerhalb einer Woche, anzuzeigen und die Anspruchserhebung durch den geschädigten Dritten sowie die Einleitung eines damit im Zusammenhang stehenden verwaltungsbehördlichen oder gerichtlichen Verfahrens anzuzeigen. Außerdem darf der Versicherungsnehmer ohne Einwilligung des Versicherers die Entschädigungsansprüche des geschädigten Dritten weder anerkennen noch einen bedingten Zahlungsbefehl in Rechtskraft erwachsen lassen."
        //         }, {
        //             "title": "Artikel 5 - Versicherungsschutz für das Ausland",
        //             "description": "Vom Versicherungsschutz ausgeschlossen sind Ersatzansprüche aus der Verwendung des versicherten Fahrzeuges bei einer kraftfahrsportlichen Veranstaltung, bei der es auf die Erzielung einer Höchstgeschwindigkeit ankommt."
        //         }],
        //         "result": {
        //             "Prüfung von Mitverschulden oder Obliegenheitsverletzungen": "Sollte der Versicherungsnehmer den Schaden durch Fahrlässigkeit oder mangelnde Wartung verursacht oder vergrößert haben, könnte dies die Deckung beeinträchtigen, insbesondere im Hinblick auf grobe Fahrlässigkeit.Wenn der Versicherungsnehmer seine Schadensminderungspflicht vernachlässigt hat, könnte dies zu einer Kürzung oder Ablehnung der Versicherungsleistung führen.",
        //             "Prüfung des Schadenumfangs": "Die Schadenshöhe ist nicht angegeben und muss ermittelt werden.",
        //             "Prüfung der versicherten Gefahren": "Ein Verstopfungsschaden durch Leitungswasseraustritt ist gedeckt, sofern er nicht durch eine ausgeschlossene Gefahr (z.B. Ausfall von Versorgungsleistungen) verursacht wurde.",
        //             "Prüfung der Kausalität": "Wenn der Schaden durch eine versicherte Ursache, wie z.B. Leitungswasseraustritt, entstand, besteht ein ursächlicher Zusammenhang mit dem Versicherungsereignis."
        //         }
        //     }
        // };
        // setClaim(newclaim);
        // console.log(newclaim, claimProps);
        if (claimProps) {
            if ((claimProps?.insuranceType === "Kfz-Versicherung" || claimProps?.insurance === "Kfz-Haftpflichtversicherung") && claimProps?.chatVehicle !== null) {
                setCarCondition(true);
            };
            if (claimProps?.insuranceType === "Privathaftpflichtversicherung" && claimProps?.chatLiability !== null) {
                setPhvCondition(true);
            };
            if (claimProps?.claim) {
                setNoClaim(false);
            };

            if (claimProps?.personalInjury && claimProps?.chatClaimRouting?.car === false) {
                setPersonalInjury(true);
            };
            setClaim(claimProps);
        };
        const handleResize = () => {
            checkOverflow1();
            checkOverflow2();
            checkOverflow3();
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        //eslint-disable-next-line
    }, [claimProps]);
    const checkOverflow1 = () => {
        if (contentRef1.current) {
            const hasOverflow = contentRef1.current.scrollHeight > contentRef1.current.clientHeight;
            setIsOverflowing1(hasOverflow);
        };
    };
    const checkOverflow2 = () => {
        if (contentRef2.current) {
            const hasOverflow = contentRef2.current.scrollHeight > contentRef2.current.clientHeight;
            setIsOverflowing2(hasOverflow);
        };
    };
    const checkOverflow3 = () => {
        if (contentRef3.current) {
            const hasOverflow = contentRef3.current.scrollHeight > contentRef3.current.clientHeight;
            setIsOverflowing3(hasOverflow);
        };
    };

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Question = ({ question, answer, newRisk }) => {

        if (newRisk) {
            setRisk(newRisk);
        };

        return (
            <Grid container columnGap={belowsm ? 0 : 2}>
                <Box sx={{ flexGrow: belowsm ? 1 : 0 }}>
                    <Typography color={theme.palette.text.primary}>{question}</Typography>
                </Box>
                <Typography color={theme.palette.text.primary}>{answer}</Typography>
            </Grid>
        );
    };

    const getInsurances = () => {
        if (claim?.complex && claim?.chatClaimRouting?.car && claim?.chatVehicle?.complexInsurances) {
            return claim?.chatVehicle?.complexInsurances;
        };
        if (claim?.complex && claim?.chatProperty?.complexInsurances) {
            return claim?.chatProperty?.complexInsurances;
        };
        return {};
    };

    const ComplexInsurances = ({ keyv, value }) => {

        return (
            <Fragment>
                <Grid item xs={12} zeroMinWidth>
                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0 }}>{keyv ? `${keyv}:` : ""}</Typography>
                    <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(value)}</Typography>
                </Grid>
                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                    <Typography color={theme.palette.text.primary}>{checkInput(value)}</Typography>
                </Grid>
            </Fragment>
        );
    };

    const ControlQuestions = ({ question }) => {

        return (
            <Grid container columnGap={belowsm ? 0 : 2}>
                <Grid item xs={12} zeroMinWidth>
                    <Typography noWrap color={theme.palette.text.primary} fontWeight={600} sx={{ flexGrow: belowsm ? 1 : 0 }}>{question.label ? `${question.label}:` : ""}</Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography color={theme.palette.text.primary}>{question.confidence ? `${question.confidence * 100}% ${question.description ? question.description : ""}` : question.description ? question.description : ""}</Typography>
                </Grid>
            </Grid>
        );
    };

    const Coverage = ({ coverage }) => {

        return (
            <Grid container columnGap={belowsm ? 0 : 2}>
                <Grid item xs={12} zeroMinWidth>
                    <Typography noWrap={belowsm ? false : true} color={theme.palette.text.primary} fontWeight={600} sx={{ flexGrow: belowsm ? 1 : 0 }}>{coverage?.title ? `* ${coverage?.title}:` : ""}</Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography color={theme.palette.text.primary}>{coverage?.description ? coverage?.description : ""}</Typography>
                </Grid>
            </Grid>
        );
    };

    const Condition = ({ condition }) => {

        return (
            <Grid container columnGap={belowsm ? 0 : 2}>
                <Grid item xs={12} zeroMinWidth>
                    <Typography noWrap={belowsm ? false : true} color={theme.palette.text.primary} fontWeight={600} sx={{ flexGrow: belowsm ? 1 : 0 }}>{condition?.title ? `* ${condition?.title}:` : ""}</Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography color={theme.palette.text.primary}>{condition?.description ? condition?.description : ""}</Typography>
                </Grid>
            </Grid>
        );
    };

    const Result = ({ keyv, value }) => {

        return (
            <Fragment>
                <Grid item xs={12} zeroMinWidth>
                    <Typography noWrap={belowsm ? false : true} color={theme.palette.text.primary} fontWeight={600} sx={{ flexGrow: belowsm ? 1 : 0 }}>{keyv ? `* ${keyv}:` : ""}</Typography>
                    <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(value)}</Typography>
                </Grid>
                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                    <Typography color={theme.palette.text.primary}>{checkInput(value)}</Typography>
                </Grid>
            </Fragment>
        );
    };



    if (noClaim) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal" }}>Diese Meldung ist keine Schadenmeldung.</Typography>
            </Box>
        )
    };

    if (personalInjury) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal" }}>Meldungen mit Hinweisen auf Personenschäden können nicht verarbeitet werden.</Typography>
            </Box>
        )
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mt: belowsm ? 2 : 0 }}>
            <Grid item xs={12} sx={{ maxHeight: belowmd ? "none" : "198px" }}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ pr: belowmd ? 0 : 1 }}>
                        <Box sx={{ height: belowsm ? "auto" : "100%", border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                            <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Übersicht</Typography>
                            <Box sx={{ p: 1 }}>
                                <Grid container>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: claim?.claim ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                            {claim?.claim ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>{claim?.claim ? "Als Schadenmeldung erkannt" : "Achtung: Meldung ist kein Schaden"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ display: claim?.attachments?.length > 0 ? "flex" : "none" }}>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: claim?.consistent ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                            {claim?.consistent ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>{claim?.consistent ? "Anhänge stimmen mit Schadenbeschreibung überein" : "Achtung: Anhänge passen nicht zum Schaden"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: claim?.multipleConcerns ? "error.main" : "success.main", mr: 2, mt: .2 }}>
                                            {claim?.multipleConcerns ? <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} /> : <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>{claim?.multipleConcerns ? "Achtung: Ein weiteres, nicht schadenbezogenes Anliegen erkannt" : "Kein weiteres, nicht schadenbezogenes Anliegen erkannt"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ display: claim?.complex ? "flex" : "none" }}>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", mr: 2, mt: .2 }}>
                                            <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>Achtung: möglicherweise Komplex oder Verbundschaden!</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ display: claim?.personalInjury && claim?.chatClaimRouting?.car ? "flex" : "none" }}>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", mr: 2, mt: .2 }}>
                                            <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>Achtung: möglicherweise Personenschaden!</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ display: claim?.chatClaimRouting.negligence ? "flex" : "none" }}>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", mr: 2, mt: .2 }}>
                                            <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography color={theme.palette.text.primary}>Achtung: Fahrlässigkeit oder Vorsatz prüfen.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ pl: belowmd ? 0 : 1, mt: belowmd ? 2 : 0 }}>
                        <Box sx={{ height: belowsm ? "auto" : "100%", border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                            <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Schadenidentifikationsdaten</Typography>
                            <Box sx={{ p: 1 }}>
                                <Grid container columnGap={2}>
                                    <Grid item>
                                        <Typography noWrap color={theme.palette.text.primary}>Police:</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>Schadennummer:</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>Schadenmelder*in:</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>E-Mail:</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>Telefon:</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>Meldedatum:</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.policy)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.claimNumber)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.reporter)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.contactEmail)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.contactPhone)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary}>{claim?.sentAt ? moment(claim?.sentAt).format("DD.MM.YYYY HH:mm") : checkInput(claim?.sentAtString)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Schadenklassifikation</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container>
                        <Grid item xs={12} sm={6} zeroMinWidth>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={!belowmd ?? "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Meldungsart:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.messageType)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Versicherungsart:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.insuranceType)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Versicherung:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.insurance)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Risiko:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.risk)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Ursache:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.cause)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.messageType)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.insuranceType)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.insurance)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary}>{checkInput(claim?.risk)}</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>{checkInput(claim?.cause)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pl: belowsm ? 0 : 2, mt: belowsm ? 2 : 0 }}>
                            <Grid container>
                                <Grid item>
                                    <Typography noWrap color={theme.palette.text.primary}>Konfidenz:</Typography>
                                </Grid>
                                <Grid item sx={{ px: 2, mt: 1.25, flexGrow: 1 }}>
                                    <LinearProgress color={claim?.riskConfidence <= 0.3 ? "error" : claim?.riskConfidence <= 0.5 ? "warning" : "success"} variant={claim?.riskConfidence ? "determinate" : "indeterminate"} value={claim?.riskConfidence * 100} />
                                </Grid>
                                <Grid item>
                                    <Typography noWrap color={theme.palette.text.primary}>{`(${claim?.riskConfidence * 100}%)`}</Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto", mt: .5 }}>
                                <Typography color={theme.palette.text.primary}>{checkInput(claim?.riskDerivation)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Schadendaten</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container sx={{ pb: isOverflowing1 ? 1 : 0 }}>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ width: "188px", pr: belowsm ? 0 : 2 }}>Schadendatum:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "none" : "block" }}>{`${checkInput(claim?.lossDate)} ${claim?.lossDate && claim?.lossTime && claim?.lossTime !== "unbekannt" ? claim?.lossTime : ""} ${claim?.lossDate && claim?.lossDateExplicit ? "" : "(geschätzt)"}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: belowsm ? "flex" : "none" }}>
                            <Typography color={theme.palette.text.primary}>{`${checkInput(claim?.lossDate)} ${claim?.lossDate && claim?.lossTime && claim?.lossTime !== "unbekannt" ? claim?.lossTime : ""} ${claim?.lossDate && claim?.lossDateExplicit ? "" : "(geschätzt)"}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pl: belowsm ? 0 : 2 }}>
                            <Box ref={contentRef1} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                <Typography color={theme.palette.text.primary}>{claim?.lossDateDerivation?.length > 0 ? claim?.lossDateDerivation : ""}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={2} sx={{ pt: isOverflowing2 ? 1 : 0, display: belowsm ? "none" : "flex" }}>
                        <Grid item>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ width: "174px", pr: belowsm ? 0 : 2 }}>Schadenort:</Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Box ref={contentRef2} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                <Typography noWrap color={theme.palette.text.primary}>{`${checkInput(claim?.lossLocation)} ${claim?.lossLocation && claim?.explicitLossLocation ? "" : "(geschätzt)"}`}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={belowsm ? 0 : 2}>
                        <Grid item>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0, display: belowsm ? "block" : "none" }}>Schadenort:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{`${checkInput(claim?.lossLocation)} ${claim?.lossLocation && claim?.explicitLossLocation ? "" : "(geschätzt)"}`}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ width: belowsm ? "auto" : "174px", flexGrow: belowsm ? 1 : 0 }}>Schadenobjekt:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.damagedObject)}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ width: "174px" }}>Kategorie:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.damagedObjectCategory)}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ width: "174px" }}>Schadenhöhe:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.damageSum)}</Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.damagedObject)}</Typography>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.damagedObjectCategory)}</Typography>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.damageSum)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={belowsm ? 0 : 2} sx={{ pt: isOverflowing3 ? 1 : 0 }}>
                        <Grid item>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0, width: "174px" }}>Schadenbeschreibung:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.lossDescription)}</Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                            <Box ref={contentRef3} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                <Typography color={theme.palette.text.primary}>{checkInput(claim?.lossDescription)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: carCondition ? "block" : "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Fahrzeugdaten</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Kennzeichen:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carLicensePlate, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Fahrgestellnummer:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carVin, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Baujahr:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carYear, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Kilometerstand:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carMileage, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carLicensePlate, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carVin, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carYear, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carMileage, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pl: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Marke:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carMake, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Modell:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carModel, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Farbe:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carColor, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carMake, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carModel, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carColor, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ pt: 1 }}>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Dritt Kennzeichen:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdLicensePlate, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Dritt Fahrgestellnummer:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdVin, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>Dritt Baujahr:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdYear, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdLicensePlate, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdVin, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdYear, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pl: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Dritt Marke:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdMake, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Dritt Modell:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdModel, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "174px" }}>Dritt Farbe:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdColor, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdMake, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdModel, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdColor, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ pt: belowmd ? 0 : 1 }}>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Drittfahrzeug Fahrername:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdDriverName, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Drittfahrzeug Addresse:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdDriverAddress, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Drittfahrzeug Führerschein:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carThirdDriverLicense, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdDriverName, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdDriverAddress, carCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdDriverLicense, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} zeroMinWidth sx={{ pl: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Drittfahrzeug Schaden:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatVehicle?.carDamage, carCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, pt: belowsm ? 1 : 0 }}>Abdeckung:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none", maxHeight: belowmd ? "84px" : "60px", overflow: "auto" }}>{checkInput(claim?.chatVehicle?.coverage, carCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatVehicle?.carThirdDamage, carCondition)}</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ maxHeight: belowmd ? "84px" : "60px", overflow: "auto" }}>{checkInput(claim?.chatVehicle?.coverage, carCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: phvCondition ? "block" : "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Privathaftpflichtdaten</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container>
                        <Grid item xs={12} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                            <Grid container columnGap={belowmd ? 0 : 2}>
                                <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Dritt Name:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatLiability?.thirdName, phvCondition)}</Typography>
                                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Dritt Addresse:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(claim?.chatLiability?.thirdAddress, phvCondition)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatLiability?.thirdName, phvCondition)}</Typography>
                                    <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(claim?.chatLiability?.thirdAddress, phvCondition)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Zahlungsdaten</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container columnGap={belowsm ? 0 : 2}>
                        <Grid item xs="auto" zeroMinWidth>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0 }}>IBAN:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.paymentIban)}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0 }}>Zahlungsempfänger*in:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.paymentRecipient)}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0 }}>Betrag laut Meldung:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.paymentAmount)}</Typography>
                            <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0 }}>Zahlungsgrund / Referenz:</Typography>
                            <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(claim?.paymentInfo)}</Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.paymentIban)}</Typography>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.paymentRecipient)}</Typography>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.paymentAmount)}</Typography>
                            <Typography color={theme.palette.text.primary}>{checkInput(claim?.paymentInfo)}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: claim?.complex ? "block" : "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Komplex / Verbundschaden</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container columnGap={belowsm ? 0 : 2}>
                        {Object.entries(getInsurances()).map(([key, value]) => { return <ComplexInsurances keyv={key} value={value} /> })}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: claim?.controlQuestions?.length > 0 ? "block" : "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Qualitätskontrolle</Typography>
                <Box sx={{ p: 1 }}>
                    {claim?.controlQuestions?.map((e, i) => { return <ControlQuestions question={e} key={i} /> })}
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, mb: belowsm ? 2 : 0, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: claim?.coverageDetermination ? "block" : "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Deckungseinschätzung</Typography>
                <Box sx={{ p: 1 }}>
                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0, fontSize: "1.25rem", color: "text.secondary", fontWeight: 600 }}>Leistungen:</Typography>
                    {claim?.coverageDetermination?.coverages?.map((c, i) => { return <Coverage coverage={c} key={i} /> })}
                    {claim?.coverageDetermination?.coverages?.length <= 0 ? <Typography color={theme.palette.text.primary}>Keine Leistungen vorhanden.</Typography> : null}
                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0, mt: 1, fontSize: "1.25rem", color: "text.secondary", fontWeight: 600 }}>Bedingungen:</Typography>
                    {claim?.coverageDetermination?.conditions?.map((c, i) => { return <Condition condition={c} key={i} /> })}
                    {claim?.coverageDetermination?.conditions?.length <= 0 ? <Typography color={theme.palette.text.primary}>Keine Bedingungen vorhanden.</Typography> : null}
                    <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowsm ? 1 : 0, mt: 1, fontSize: "1.25rem", color: "text.secondary", fontWeight: 600 }}>Einschätzung:</Typography>
                    {Object.entries(claim?.coverageDetermination?.result ?? {}).map(([key, value]) => { return <Result keyv={key} value={value} /> })}
                    {claim?.coverageDetermination?.result === null ? <Typography color={theme.palette.text.primary}>Keine Einschätzung vorhanden.</Typography> : null}
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, mb: belowsm ? 2 : 0, border: 1, borderColor: "text.secondary", borderRadius: "4px", display: "none" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Schadenfragen</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container sx={{ mb: 2 }}>
                        <Grid item>
                            <Avatar sx={{ height: 20, width: 20, bgcolor: risk ? "error.main" : "success.main", mr: 2, mt: .2 }}>
                                {risk ? <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} /> : <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />}
                            </Avatar>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography color={theme.palette.text.primary}>{risk ? `Achtung: Schadenfragen ergeben abweichendes Risiko: ${risk}` : "Schadenfragen ergeben kein abweichendes Risiko"}</Typography>
                        </Grid>
                    </Grid>
                    {claim.questions?.map((q, i) => { return <Question key={`question-${i}`} question={q.question} answer={q.answer} newRisk={q.risk} /> })}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Overview;