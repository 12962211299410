import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

export const useAxios = () => {
  const axiosInstance = useRef();
  const auth = useAuth();

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: window.env.REACT_APP_apiUri,
      headers: {
        Authorization: auth.isAuthenticated ? `Bearer ${auth.user?.access_token}` : "",
      },
    });

    return () => {
      axiosInstance.current = undefined;
    };
  }, [auth.isAuthenticated, auth.user?.access_token]);

  return axiosInstance;
};