import ExpandMore from '@mui/icons-material/ExpandMore';
import { Backdrop, CircularProgress, Collapse, Container, Grid, List, ListItemButton, ListItemText, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAxios } from "../../utlis/useAxios";
import ViewHandler from './ViewHandler';
import noPreviewDark from '../../images/no-preview-available-dark.png';
import noPreviewLight from '../../images/no-preview-available-light.png';
import moment from 'moment';

const Complete = ({ reportRef }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const claimUUIDfromURL = query.get('claim');
    const claimUUIDfromStorage = localStorage.getItem("claim");

    //eslint-disable-next-line
    const [claim, setClaim] = useState();
    const [failed, setFailed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [multiplePhotos, setMultiplePhotos] = useState(false);
    const [failedAttachments, setFailedAttachments] = useState([]);

    const attempted = useRef(false);
    const preview = useRef([]);
    const noPreviewDarkURL = useRef(null);
    const noPreviewLightURL = useRef(null);

    const axiosInstance = useAxios();

    useEffect(() => {
        if (reportRef.current) {
            reportRef.current = {
                "email": "",
                "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
                "content": "",
                "attachments": [],
                "contracts": [],
                "loading": false
            };
        }
        if (!claim && !attempted.current && (claimUUIDfromURL || claimUUIDfromStorage)) {
            fetchConveration();
        }
        if (claim?.claimUUID) {
            if (claim?.attachments?.length > 0) {
                let count = 0;
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    const attachment = claim?.attachments[i];
                    if (attachment?.documentType === "Foto des Schadens") {
                        count += 1;
                        if (count >= 2) {
                            setMultiplePhotos(true);
                        }
                    };
                };
            };
            if (preview.current?.length === 0 && claim?.claimUUID) {
                fetchFallback();
                fetchAllAttachments();
            }
            setFailed(false);
        }
        //eslint-disable-next-line
    }, [claim, preview.current])

    const createObjectUrl = (image, dark) => {
        fetch(image)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                if (dark) {
                    noPreviewDarkURL.current = url;
                } else {
                    noPreviewLightURL.current = url;
                }
            })
            .catch(err => console.error(err));
    };

    const fetchFallback = async () => {
        await createObjectUrl(noPreviewDark, true);
        await createObjectUrl(noPreviewLight, false);
    };

    const fetchConveration = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/chat/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            setClaim(res.data);
        }).catch(() => setFailed(true));
        attempted.current = true;
        setLoading(false);
    };

    const fetchAllAttachments = async () => {
        for (let i = 0; i < claim?.attachments?.length; i++) {
            // console.log(claim?.attachments[i]?.attachmentUUID);
            await fetchPreview(claim?.attachments[i]);
        };
    };

    const fetchPreview = async (attachment) => {
        setLoading(true);
        for (let i = 0; i < attachment?.src?.length; i++) {
            const current = attachment?.src[i] ?? undefined;
            await axiosInstance.current({
                url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}/${current}`,
                method: "GET",
                responseType: "blob"
            }).then((res) => {
                const previewObj = {
                    id: current,
                    data: URL.createObjectURL(res.data)
                }
                preview.current.push(previewObj);
            }).catch(() => setFailedAttachments([...failedAttachments, attachment?.attachmentUUID]));
        };
        setLoading(false);
    };

    //eslint-disable-next-line
    const handleUserResponse = async (response, id) => {
        setLoading(true);

        const body = {
            "questionUUID": id,
            "message": response
        };

        await axiosInstance.current({
            url: `/chat/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "POST",
            data: body
        }).then((res) => {
            setClaim(res.data);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowmd ? "calc(100vh - 145px)" : "calc(100vh -75px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    if ((!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        return (
            <Container sx={{ height: belowmd && !belowsm ? "calc(100vh - 145px)" : "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color={theme.palette.text.primary}>Keine Schadenmeldung vorhanden.</Typography>
            </Container>
        )
    };

    const getContent = (photos, attachments) => {
        if (photos) {
            return photos;
        }
        if (attachments) {
            let matching = [];
            for (let i = 0; i < attachments?.length; i++) {
                if (attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(attachments[i]);
                }
            };
            return matching;
        };
        return [];
    }

    const DesktopView = () => {

        const [openListClaim, setOpenListClaim] = useState(true);
        const [openListAttachments, setOpenListAttachments] = useState(true);

        const viewHandlerRef = useRef(null);

        useEffect(() => {
            openOverview();
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < claim?.attachments?.length; o++) {
                if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = () => viewHandlerRef.current.openOverview(claim);
        const openMessage = () => viewHandlerRef.current.handleMessage(claim);
        const openPhoto = () => {
            let sourceArray = [];
            for (let i = 0; i < claim?.photos?.length; i++) {
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.photos[i]?.attachmentUUID}`;
                const name = claim?.photos[i]?.filename;
                const type = claim?.photos[i]?.contentType;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": name,
                    "contentType": type
                };
                sourceArray.push(sourceObj);
            }
            if (!claim?.photos?.length && claim?.attachments?.length > 0) {
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.attachments[i]?.attachmentUUID}`;
                        const name = claim?.attachments[i]?.filename;
                        const type = claim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(claim?.photos, claim?.attachments), getMatchingPhotos(), sourceArray);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = () => {
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                if (type === "Fnol") {
                    viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray);
                };
                if (type === "PoliceReport") {
                    viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray);
                };
                if (type === "AccountingDocument" || type === "OtherDocument") {
                    viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray);
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            };

            return (
                <ListItemButton onClick={() => { openAttachment() }} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                    <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <Grid item xs={2} sx={{ height: "98%", border: 1, borderColor: "text.secondary", borderRadius: "4px", mt: 2, overflow: "auto" }}>
                    <List disablePadding>
                        <ListItemButton onClick={handleListClaim} sx={{ py: 0, borderRadius: "4px" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary}>Meldung</Typography>} />
                            <ExpandMore sx={{ color: theme.palette.text.primary, ml: 1, mt: .5, transition: 'transform 250ms', transform: openListClaim ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                        </ListItemButton>
                        <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Übersicht</Typography>} />
                                </ListItemButton>
                                <ListItemButton disabled sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Daten ergänzen</Typography>} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleListAttachments} sx={{ py: 0, borderRadius: "4px" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary}>{claim?.attachments?.length > 0 ? "Anhänge" : "Anhang"}</Typography>} />
                            <ExpandMore sx={{ color: theme.palette.text.primary, ml: 1, mt: .5, transition: 'transform 250ms', transform: openListAttachments ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                        </ListItemButton>
                        <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                            <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Meldung</Typography>} />
                            </ListItemButton>
                            <List component="div" disablePadding sx={{ height: "100%" }}>
                                <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "4px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>{getMatchingPhotos()?.length > 1 ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                                </ListItemButton>
                                {claim?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                            </List>
                        </Collapse>
                    </List>
                </Grid>
                <Grid item xs={10} sx={{ height: "100%", pl: 2, pt: 2 }}>
                    <ViewHandler ref={viewHandlerRef} />
                </Grid>
            </Fragment>
        );
    };

    const MobileView = () => {

        const [openListClaim, setOpenListClaim] = useState(false);
        const [openListAttachments, setOpenListAttachments] = useState(false);

        const viewHandlerRef = useRef(null);

        useEffect(() => {
            openOverview();
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < claim?.attachments?.length; o++) {
                if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = () => {
            viewHandlerRef.current.openOverview(claim);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openMessage = () => {
            viewHandlerRef.current.handleMessage(claim);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openPhoto = () => {
            let sourceArray = [];
            for (let i = 0; i < claim?.photos?.length; i++) {
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.photos[i]?.attachmentUUID}`;
                const name = claim?.photos[i]?.filename;
                const type = claim?.photos[i]?.contentType;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": name,
                    "contentType": type
                };
                sourceArray.push(sourceObj);
            }
            if (!claim?.photos?.length && claim?.attachments?.length > 0) {
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.attachments[i]?.attachmentUUID}`;
                        const name = claim?.attachments[i]?.filename;
                        const type = claim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(claim?.photos, claim?.attachments), getMatchingPhotos(), sourceArray);
            setOpenListAttachments(false);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || (attachment.src && attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0)) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = () => {
                setOpenListClaim(false);
                setOpenListAttachments(false);
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                if (type === "Fnol") {
                    viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray);
                };
                if (type === "PoliceReport") {
                    viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray);
                };
                if (type === "AccountingDocument" || type === "OtherDocument") {
                    viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray);
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            }

            return (
                <ListItemButton onClick={() => { openAttachment() }} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                    <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <List disablePadding sx={{ width: "100%", borderBottom: 1, borderBottomColor: "text.secondary" }}>
                    <ListItemButton onClick={handleListClaim} sx={{ p: 0, pl: .5, borderRadius: "6px" }}>
                        <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary}>Meldung</Typography>} />
                        <ExpandMore sx={{ color: theme.palette.text.primary, ml: 1, mt: .5, transition: 'transform 250ms', transform: openListClaim ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                    </ListItemButton>
                    <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Übersicht</Typography>} />
                            </ListItemButton>
                            <ListItemButton disabled sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Daten ergänzen</Typography>} />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleListAttachments} sx={{ p: 0, pl: .5, borderRadius: "6px" }}>
                        <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary}>Anhänge</Typography>} />
                        <ExpandMore sx={{ color: theme.palette.text.primary, ml: 1, mt: .5, transition: 'transform 250ms', transform: openListAttachments ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                    </ListItemButton>
                    <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{ height: "100%" }}>
                            <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>Meldung</Typography>} />
                            </ListItemButton>
                            <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "6px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                <ListItemText primary={<Typography noWrap color={theme.palette.text.primary}>{multiplePhotos ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                            </ListItemButton>
                            {claim?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                        </List>
                    </Collapse>
                </List>
                <ViewHandler ref={viewHandlerRef} />
            </Fragment>
        );
    };

    return (
        <Grid container sx={{ p: 2, height: belowsm ? "calc(100vh - 247px)" : belowmd ? "calc(100vh - 138px)" : "calc(100vh - 84px)" }}>
            {belowsm ? <MobileView /> : <DesktopView />}
        </Grid>
    );

};

export default Complete;