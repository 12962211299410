import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Collapse, Divider, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { jwtDecode } from "jwt-decode";
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
// import swiftRobin from '../images/swift_robin.png';
import LogoIcon from '../icons/Logo';
import UserDialog from './Dialog/UserDialog';

const Nav = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const [decoded, setDecoded] = useState(undefined);
    const [collapse, setCollapse] = useState(false);
    const [userCollapse, setUserCollapse] = useState(false);

    const profileRef = useRef(null);

    useEffect(() => {
        if (!decoded && auth?.user?.id_token) {
            setDecoded(jwtDecode(auth?.user?.id_token));
        }
        if (!belowsm) {
            setCollapse(false);
            setUserCollapse(false);
        }
        document.body.style.backgroundColor = theme.palette.background.default;
        sessionStorage.removeItem("redirect");

        //eslint-disable-next-line
    }, [belowsm, theme.palette.mode, auth.events, auth?.user?.id_token]);

    const handleClick = () => {
        setCollapse(!collapse);
        setUserCollapse(false);
    };

    const handleMobileUserClick = () => {
        setUserCollapse(!userCollapse);
        setCollapse(false);
    };

    const handleLogout = () => {
        setUserCollapse(false);
        auth.signoutSilent();
    };

    const handlePageChange = (path) => {
        navigate(path);
        setCollapse(!collapse);
    };

    const handleProfileClick = () => profileRef.current.open(decoded?.email ?? "");

    return (
        <AppBar position="sticky" sx={{ boxShadow: collapse || userCollapse ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", bgcolor: theme.palette.mode === "dark" ? "primary.dark" : "primary.main", display: auth.isAuthenticated ? "flex" : "none" }}>
            <Toolbar>
                <Grid container flexDirection="column">
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                        <IconButton
                            onClick={handleClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            sx={{ mr: belowsm ? 0 : 2, my: 1, display: belowsm ? "inline-flex" : "none" }}
                        >
                            {collapse ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box sx={{ mt: belowsm ? 0 : .5, flexGrow: belowsm ? 1 : 0, display: "flex", justifyContent: "center", alignItems: "center", height: "64px" }}>
                            {/* <img alt="logo" src={swiftRobin} style={{ height: "65%" }} /> */}
                            <LogoIcon />
                            <Typography variant="h6" fontWeight={700} component="div" noWrap color={"inherit"} sx={{ ml: 2, display: belowsm ? "none" : "flex" }}>
                                Swift Robin
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: belowsm ? "none" : "flex" }} />
                        <Box sx={{ display: belowmd ? "none" : "flex", justifyContent: "center", mr: 2 }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Schaden melden</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meldung anzeigen</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="text" color="inherit" disableRipple sx={{ my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meine Schäden</Button>
                        </Box>
                        <Button size="medium" onClick={handleProfileClick} variant="contained" color="profile" disableElevation sx={{ my: 2, textTransform: "none", color: "profile.contrastText", borderRadius: "8px", border: 1, borderColor: "profile.main", display: belowsm ? "none" : "inline-flex", width: "100px", mr: 1 }}>Profil</Button>
                        <Button size="medium" onClick={handleLogout} variant="contained" color="signout" disableElevation sx={{ my: 2, textTransform: "none", color: "signout.contrastText", borderRadius: "8px", border: 1, borderColor: "signout.border", display: belowsm ? "none" : "inline-flex", width: "100px" }}>Abmelden</Button>
                        <IconButton
                            onClick={handleMobileUserClick}
                            size="large"
                            edge="end"
                            color="inherit"
                            sx={{ my: 1, display: belowsm ? "inline-flex" : "none" }}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sx={{ display: belowmd && !belowsm ? "block" : "none" }}>
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", borderTop: 1, borderColor: "inherit", pt: 1 }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Schaden melden</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meldung anzeigen</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="text" color="inherit" disableRipple sx={{ mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meine Schäden</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={collapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem onClick={() => { handlePageChange("/report"); }}>
                            <ListItemText inset>Schaden melden</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/complete"); }}>
                            <ListItemText inset>Meldung anzeigen</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/claims"); }}>
                            <ListItemText inset>Meine Schäden</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
            <Collapse in={userCollapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem>
                            <ListItemIcon>
                                <HowToRegIcon sx={{ color: theme.palette.text.primary }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography fontSize="inherit" noWrap>{`Angemeldet mit ${decoded?.email ?? ""}`}</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon sx={{ color: theme.palette.text.primary }} />
                            </ListItemIcon>
                            <ListItemText>Abmelden</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
            <UserDialog ref={profileRef} />
        </AppBar>
    );
};

export default Nav;