import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useAuth } from "react-oidc-context";

const ErrorDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [error, setError] = useState();

    const { signoutSilent } = useAuth();


    useImperativeHandle(ref, () => ({
        open(err) {
            setOpen(true);
            setError(err);
        }
    }));

    const getMessage = (error) => {
        if (error === 401) {
            return "Unautorisiert / Login abgelaufen";
        }
        if (error === 413) {
            return "Die maximale Dateigröße wurde überschritten";
        }
        return "Ein unerwarteter Fehler ist aufgetreten";
    };

    const getText = (error) => {
        if (error === 401) {
            return "Bitte melden Sie sich erneut an.";
        }
        if (error === 413) {
            return "Bitte komprimieren Sie Ihre Dateien.";
        }
        return "Bitte versuchen Sie es später noch einmal";
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleLogout = () => {
        signoutSilent();
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={error === 401 ? null : handleClose}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{getMessage(error)}</DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal", textAlign: "center" }}>Die von Ihnen ausgeführte Aktion ist fehlgeschlagen.<br />{getText(error)}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation onClick={error === 401 ? handleLogout : handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{error === 401 ? "Erneut anmelden" : "Schließen"}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default ErrorDialog;