import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children, redirect }) {

    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isAuthenticated && redirect !== undefined && redirect !== null) {
            sessionStorage.setItem("redirect", redirect);
        };
        //eslint-disable-next-line
    }, []);

    return isAuthenticated ? children : <Navigate replace to="/login" />;
};